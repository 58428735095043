export default defineNuxtRouteMiddleware(async (to) => {
    const auth = useAuthStore();
    const isSystemAdmin = await auth.getIsSystemAdmin();
    const isCSPAdmin = await auth.getIsCspAdmin();

  if (to.path.includes('/admin/users') && !isSystemAdmin) {
        return navigateTo('/');
    }

  if (to.path.includes('/csp-admin/users') && !isCSPAdmin) {
        return navigateTo('/');
    }
});